import { Link } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import React from 'react';
import chapter1Blob from '../../images/guides/how-success-is-written/chapter1_blob.svg';
import chapter2Blob from '../../images/guides/how-success-is-written/chapter2_blob.svg';
import chapter3Blob from '../../images/guides/how-success-is-written/chapter3_blob.svg';
import chapter4Blob from '../../images/guides/how-success-is-written/chapter4_blob.svg';
import PageSection from '../ui/PageSection';
import useChapters from './useChapters';
import styles from './HowSuccessIsWrittenContent.module.scss';

const blobBackgrounds = [
  { img: chapter1Blob, className: styles.chapter1Blob },
  { img: chapter2Blob, className: styles.chapter2Blob },
  { img: chapter3Blob, className: styles.chapter3Blob },
  { img: chapter4Blob, className: styles.chapter4Blob },
];

const HowSuccessIsWrittenContent: React.FunctionComponent = () => (
  <PageSection background="light">
    <div className={styles.content}>
      <div className={styles.chapterContainer}>
        {useChapters().map((chapter, idx) => (
          <div className={styles.chapter} key={chapter.slug}>
            {blobBackgrounds[idx] && (
              <img
                // eslint-disable-next-line react/no-array-index-key
                key={idx}
                className={blobBackgrounds[idx].className}
                src={blobBackgrounds[idx].img}
                role="presentation"
                alt=""
              />
            )}
            <p className={styles.chapterNum}>Chapter {chapter.chapterNumber}</p>
            <h2 className={styles.chapterTitle}>{chapter.title}</h2>
            <div className={styles.interviewGrid}>
              {chapter.interviews.map((interview) => (
                <Link to={interview.slug} key={interview.slug}>
                  <div className={styles.interview}>
                    <p className={styles.interviewCompany}>
                      {interview.company}
                    </p>
                    <h3 className={styles.interviewTitle}>{interview.title}</h3>
                    <p className={styles.interviewSummary}>
                      {interview.summary}
                    </p>
                    <div className={styles.author}>
                      <div>
                        {interview?.authorAvatar.childImageSharp ? (
                          <GatsbyImage
                            fluid={
                              interview?.authorAvatar.childImageSharp.fluid
                            }
                            className={styles.authorAvatar}
                          />
                        ) : null}
                      </div>
                      <div>
                        <p className={styles.authorName}>
                          {interview.authorName}
                        </p>
                        <p className={styles.authorTitle}>
                          {interview.authorTitle}
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  </PageSection>
);

export default HowSuccessIsWrittenContent;
