// TODO: Fix this the next time the file is edited.
/* eslint-disable import/no-unused-modules */
import React from 'react';
import Spacing from '../ui/Spacing';
import Text from '../ui/Text';
import Carousel from './Carousel';
import styles from './Testimonies.module.scss';
import TestimonyBlockquote from './TestimonyBlockquote';

interface TestimoniesProps {
  header?: string;
  theme?: 'untitled' | undefined;
  testimonies?: string[];
}

const Testimonies: React.SFC<TestimoniesProps> = ({
  header,
  theme,
  testimonies = ['scott', 'rishi'],
}) => (
  <div className={styles.container}>
    <Spacing bottom={2}>
      <Text bold caps xxSmall center primary>
        {header || 'Why companies love Slab'}
      </Text>
    </Spacing>
    <Carousel>
      {testimonies.map((slug) => (
        <TestimonyBlockquote theme={theme} key={slug} slug={slug} />
      ))}
    </Carousel>
  </div>
);

export default Testimonies;
