import classNames from 'classnames';
import React from 'react';
import styles from './PageSection.module.scss';

export type PageSectionBackground = 'light' | 'light-half' | null;

interface PageSectionProps {
  background?: PageSectionBackground;
  bordered?: boolean;
  children?: React.ReactNode;
  className?: string;
  id?: string;
  innerRef?: React.RefObject<HTMLDivElement>;
  narrow?: boolean;
}

const PageSection: React.SFC<PageSectionProps> = ({
  background,
  bordered,
  className,
  children,
  id,
  innerRef,
  narrow,
}) => (
  <div
    id={id}
    className={classNames(className, {
      [styles.backgroundLight]: background === 'light',
      [styles.backgroundLightHalf]: background === 'light-half',
      [styles.bordered]: bordered,
    })}
    ref={innerRef}
  >
    <div
      className={classNames(styles.pageSection, {
        [styles.narrow]: narrow,
      })}
    >
      {children}
    </div>
  </div>
);

export default PageSection;
