import React from 'react';
import MicrositeLayout from '../../components/layout/MicrositeLayout';
import Testimonies from '../../components/testimonies/Testimonies';
import HowSuccessIsWrittenContent from '../../components/how-success-is-written/HowSuccessIsWrittenContent';
import HowSuccessIsWrittenFooter from '../../components/how-success-is-written/HowSuccessIsWrittenFooter';
import HowSuccessIsWrittenHeader from '../../components/how-success-is-written/HowSuccessIsWrittenHeader';
import Meta from '../../components/layout/Meta';
import og from '../../images/guides/how-success-is-written/og.jpg';

const HowSuccessIsWrittenPage: React.FunctionComponent = () => (
  <MicrositeLayout>
    <Meta
      title="How Success is Written - Slab"
      image={og}
      description="Learn how top companies are using writing to fuel innovation"
    />
    <HowSuccessIsWrittenHeader />
    <Testimonies
      header="Top companies are using writing to fuel innovation"
      theme="untitled"
      testimonies={[
        'gitlab-darren-wc',
        'shopify-courtney-wc',
        'loom-vinay-wc',
        'lattice-emily-wc',
        'ramp-megan-wc',
      ]}
    />
    <HowSuccessIsWrittenContent />
    <HowSuccessIsWrittenFooter />
  </MicrositeLayout>
);

export default HowSuccessIsWrittenPage;
