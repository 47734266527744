import React from 'react';
import cover from '../../images/guides/how-success-is-written/cover.jpg';
import headerBlob from '../../images/guides/how-success-is-written/header_blob.svg';
import HowSuccessIsWrittenDownloadForm from './HowSuccessIsWrittenDownloadForm';
import styles from './HowSuccessIsWrittenHeader.module.scss';
import HowSuccessIsWrittenNav from './HowSuccessIsWrittenNav';

const HowSuccessIsWrittenHeader: React.FunctionComponent = () => (
  <div className={styles.header}>
    <HowSuccessIsWrittenNav />
    <div className={styles.headerContainer}>
      <div className={styles.grid}>
        <div className={styles.coverContainer}>
          <img
            className={styles.headerBlob}
            src={headerBlob}
            role="presentation"
            alt=""
          />
          <img
            className={styles.cover}
            src={cover}
            alt="How Success is Written eBook"
          />
        </div>
        <div className={styles.callout}>
          <h1>How Success is Written</h1>
          <p className={styles.intro}>
            Today&apos;s most successful tech companies are using writing to
            fuel innovation and growth. Learn from 14 leaders about writing
            culture, scaling asynchronously, and more.
          </p>
          <HowSuccessIsWrittenDownloadForm />
        </div>
      </div>
    </div>
  </div>
);

export default HowSuccessIsWrittenHeader;
