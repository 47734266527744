import { graphql, StaticQuery } from 'gatsby';
import React from 'react';
import {
  CompaniesYamlConnection,
  TestimoniesYamlConnection,
} from '../graphql-types';
import { Company, Testimony } from '../types';

interface TestimonyQueryProps {
  render: (testimony: Testimony) => React.ReactNode;
  slug: string;
}

const TestimonyQuery: React.SFC<TestimonyQueryProps> = ({ render, slug }) => (
  <StaticQuery
    query={graphql`
      query {
        companies: allCompaniesYaml {
          edges {
            node {
              name
              url
              logo {
                publicURL
              }
              fields {
                slug
              }
            }
          }
        }
        testimonies: allTestimoniesYaml {
          edges {
            node {
              name
              company
              title
              quote
              picture {
                publicURL
              }
              fields {
                slug
              }
            }
          }
        }
      }
    `}
    render={({
      companies,
      testimonies,
    }: {
      companies: CompaniesYamlConnection;
      testimonies: TestimoniesYamlConnection;
    }) => {
      // TODO: Fix this the next time the file is edited.
      /* eslint-disable @typescript-eslint/no-non-null-assertion */
      const testimonyYaml = testimonies.edges.find(
        (edge) => edge.node.fields!.slug === slug,
      )!.node;

      const company = companies.edges.find(
        (edge) => edge.node.fields!.slug === testimonyYaml.company,
      )!.node;
      /* eslint-enable @typescript-eslint/no-non-null-assertion */

      const testimony = {
        ...testimonyYaml,
        company: company as Company,
      };

      return render(testimony as Testimony);
    }}
  />
);

export default TestimonyQuery;
