import classNames from 'classnames';
import React from 'react';
import ReactSwipeable, {
  OnSwipedDirectionCallback,
  OnSwipingDirectionCallback,
} from 'react-swipeable';
import styles from './Carousel.module.scss';

interface CarouselProps {
  children: React.ReactNodeArray;
}

interface CarouselState {
  offset: number;
  swipingOffset: number;
}

export default class Carousel extends React.Component<
  CarouselProps,
  CarouselState
> {
  constructor(props: CarouselProps) {
    super(props);

    this.state = { offset: 0, swipingOffset: 0 };
  }

  slideCount() {
    return React.Children.count(this.props.children);
  }

  handleSwipingLeft: OnSwipingDirectionCallback = (e, absX) => {
    const swipingOffset = absX / (e.target as HTMLDivElement).clientWidth;
    this.setState({ swipingOffset });
  };

  handleSwipingRight: OnSwipingDirectionCallback = (e, absY) => {
    const swipingOffset = -(absY / (e.target as HTMLDivElement).clientWidth);
    this.setState({ swipingOffset });
  };

  handleSwipedLeft: OnSwipedDirectionCallback = (e, deltaX, isFlick) => {
    if (isFlick || deltaX / (e.target as HTMLDivElement).clientWidth > 0.2) {
      this.handleForward();
    } else {
      this.setState({ swipingOffset: 0 });
    }
  };

  handleSwipedRight: OnSwipedDirectionCallback = (e, deltaY, isFlick) => {
    if (isFlick || deltaY / (e.target as HTMLDivElement).clientWidth > 0.2) {
      this.handleBackward();
    } else {
      this.setState({ swipingOffset: 0 });
    }
  };

  handleForward = () => {
    const { offset } = this.state;
    this.setState({
      offset: (offset + 1) % this.slideCount(),
      swipingOffset: 0,
    });
  };

  handleBackward = () => {
    const { offset } = this.state;
    this.setState({ offset: Math.max(0, offset - 1), swipingOffset: 0 });
  };

  handleGoTo = (index: number) => {
    this.setState({ offset: index, swipingOffset: 0 });
  };

  render() {
    const { children } = this.props;
    const { offset, swipingOffset } = this.state;
    const activeIndex = Math.floor(offset);
    const translateX = offset + swipingOffset;

    return (
      <div>
        <div className={styles.container}>
          <button
            aria-label="Previous"
            className={styles.control}
            onClick={this.handleBackward}
            type="button"
          >
            ←
          </button>
          <div className={styles.viewport}>
            <ReactSwipeable
              onSwipingLeft={this.handleSwipingLeft}
              onSwipingRight={this.handleSwipingRight}
              onSwipedLeft={this.handleSwipedLeft}
              onSwipedRight={this.handleSwipedRight}
            >
              <div
                className={styles.inner}
                style={{ transform: `translateX(-${translateX * 100}%)` }}
              >
                {children}
              </div>
            </ReactSwipeable>
          </div>
          <button
            aria-label="Next"
            className={styles.control}
            onClick={this.handleForward}
            type="button"
          >
            →
          </button>
        </div>
        <div className={styles.indicators}>
          {children
            .filter((child) => !!child)
            .map((_child, index: number) => (
              <button
                aria-label={`Go to slide ${index}`}
                type="button"
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                onClick={() => {
                  this.handleGoTo(index);
                }}
                className={classNames(styles.indicator, {
                  [styles.active]: activeIndex === index,
                })}
              />
            ))}
        </div>
      </div>
    );
  }
}
